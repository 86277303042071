import { AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import httpClient from './httpClient';
import HttpRequestMethods from './httpRequestMethods';
import UrlConfig from '../interfaces/UrlConfig';
import RequestConfig from '../interfaces/RequestConfig';

function buildCustomizedConfig(currConfig: RequestConfig, urlConfig: UrlConfig): RequestConfig {
  const config = { ...currConfig };

  // set withCredentials
  if (urlConfig.withCredentials) {
    config.withCredentials = urlConfig.withCredentials;
  }
  // enable retry logic for fail request
  if (urlConfig.retryable) {
    config.retryable = urlConfig.retryable;
  }
  // disable cache
  if (urlConfig.noCache) {
    config.headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: 0
    };
  }
  if (urlConfig.authBearerToken) {
    config.headers = {
      'X-Auth-Bearer-Token': urlConfig.authBearerToken
    };
  }
  // append header argument
  if (urlConfig.headers) {
    config.headers = {
      ...config.headers,
      ...urlConfig.headers
    };
  }

  if (urlConfig.tracerConfig) {
    config.tracerConfig = urlConfig.tracerConfig;
  }

  return config;
}

function buildRequest(config: RequestConfig, urlConfig: UrlConfig): AxiosPromise {
  if (!urlConfig) {
    Promise.reject(new Error('No config found'));
  }
  return httpClient(buildCustomizedConfig(config, urlConfig));
}

function buildGetRequest(
  urlConfig: UrlConfig,
  params?: URLSearchParams | object,
  requestConfig?: Omit<RequestConfig, 'params'>
): AxiosPromise {
  return buildRequest(
    {
      method: HttpRequestMethods.GET,
      url: urlConfig.url,
      ...requestConfig,
      params
    },
    urlConfig
  );
}

function buildPostRequest(
  urlConfig: UrlConfig,
  data?: Document | BodyInit | object | null,
  requestConfig?: Omit<RequestConfig, 'data'>
): AxiosPromise {
  return buildRequest(
    {
      method: HttpRequestMethods.POST,
      url: urlConfig.url,
      ...requestConfig,
      data
    },
    urlConfig
  );
}

function buildPatchRequest(
  urlConfig: UrlConfig,
  data?: Document | BodyInit | null,
  requestConfig?: RequestConfig
): AxiosPromise {
  return buildRequest(
    {
      method: HttpRequestMethods.PATCH,
      url: urlConfig.url,
      ...requestConfig,
      data
    },
    urlConfig
  );
}

function buildDeleteRequest(urlConfig: UrlConfig, requestConfig?: RequestConfig): AxiosPromise {
  return buildRequest(
    {
      method: HttpRequestMethods.DELETE,
      url: urlConfig.url,
      ...requestConfig
    },
    urlConfig
  );
}

function buildBatchPromises(
  arrayNeedsBatch: string[],
  cutOff: number,
  urlConfig: UrlConfig,
  isPost: boolean,
  paramsKey: string
): Promise<AxiosResponse[]> {
  const promises: AxiosPromise[] = [];
  let startIndex = 0;
  let subArray = arrayNeedsBatch.slice(startIndex, cutOff);
  const key = paramsKey || 'userIds';
  while (subArray.length > 0) {
    const params: Record<string, string[]> = {};
    params[key] = subArray;
    if (isPost) {
      promises.push(buildPostRequest(urlConfig, params));
    } else {
      promises.push(buildGetRequest(urlConfig, params));
    }
    startIndex += 1;
    subArray = arrayNeedsBatch.slice(startIndex * cutOff, startIndex * cutOff + cutOff);
  }
  return Promise.all(promises);
}

function createCancelToken(): CancelTokenSource {
  return httpClient.CancelToken.source();
}

function isCancelled(error: any): boolean {
  return httpClient.isCancel(error);
}

export default {
  methods: HttpRequestMethods,
  get: buildGetRequest,
  post: buildPostRequest,
  delete: buildDeleteRequest,
  patch: buildPatchRequest,
  buildBatchPromises,
  createCancelToken,
  isCancelled
};
